import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Button from '@/components/Button'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

export const ButtonForgot = styled(Link)`
  ${({ theme }) => css`
    margin-top: 1.5rem;
    color: ${theme.colors.primary};
    font-family: ${theme.font.nunito};
    font-weight: 600;
    transition: all 0.3s ease-in-out 0s;
    line-height: 0.8;
    font-size: 0.875rem;
  `}
`

export const ButtonShowPassword = styled(Button)`
  padding: 0.188rem 0 0;

  svg {
    width: 1.25rem;
    max-height: 1.25rem;
  }
`
