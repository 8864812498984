import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from 'react'

import { Animation as LoadingAnimation } from '@/components/Loading/styles'

import * as S from './styles'

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonProps = {
  children?: React.ReactNode
  color?: 'primary' | 'white'
  size?: 'small' | 'medium' | 'large'
  fullwidth?: boolean
  minimal?: boolean
  loading?: boolean
  shadow?: boolean
  disabled?: boolean
  icon?: JSX.Element
  iconside?: 'left' | 'right'
  as?: React.ElementType
} & ButtonTypes

const Button: React.ForwardRefRenderFunction<S.WrapperProps, ButtonProps> = (
  {
    children,
    color = 'primary',
    icon,
    iconside = 'left',
    size = 'medium',
    type = 'button',
    fullwidth = false,
    minimal = false,
    loading = false,
    shadow = false,
    disabled = false,
    ...props
  },
  ref,
) => (
  <S.Wrapper
    type={type}
    color={color}
    size={size}
    fullwidth={fullwidth ? 1 : 0}
    iconside={iconside}
    hasicon={icon ? 1 : 0}
    minimal={minimal ? 1 : 0}
    isloading={loading ? 1 : 0}
    hasshadow={shadow ? 1 : 0}
    disabled={disabled}
    ref={ref}
    {...props}
  >
    {loading && <LoadingAnimation />}
    {!!icon && !loading && icon}
    {!!children && <span>{children}</span>}
  </S.Wrapper>
)

export default forwardRef(Button)
