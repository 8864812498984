import styled, { css } from 'styled-components'

import { ErrorPageProps } from '.'

type ExpiredType = {
  expired?: boolean
}

export const Wrapper = styled.main<ErrorPageProps>`
  ${({ theme, variant }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    background-color: ${variant === 2
      ? theme.colors.primary
      : theme.colors.mainBg};
  `}
`

export const Number = styled.h1<ExpiredType>`
  ${({ theme, expired = false }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${theme.font.nunito};
    font-weight: 900;
    color: ${theme.colors.primary};
    font-size: 8.75rem;
    line-height: ${expired ? 2 : 1};
  `}
`

export const ImageBag = styled.img`
  margin: 0 8px;
  width: 7.5rem;
`

type ShapeImageUrlType = {
  imageUrl: string
}

export const Shape = styled.section<ShapeImageUrlType>`
  ${({ theme, imageUrl = '' }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url(${imageUrl}) center center no-repeat;
    background-size: contain;
    width: 100%;
    max-width: 42.5rem;
    min-width: 22.625rem;
    height: 33.063rem;

    h2 {
      font-family: ${theme.font.quicksand};
      font-weight: 600;
      font-size: 1.375rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-family: ${theme.font.quicksand};
      font-weight: 600;
      color: ${theme.colors.gray};
      font-size: 0.625rem;
      margin-bottom: 2.25rem;
    }

    ${Number} {
      font-size: 5rem;
    }

    ${ImageBag} {
      width: 5rem;
    }

    @media (min-width: 768px) {
      h2 {
        font-size: 1.25rem;
        margin-bottom: 0.625rem;
      }

      h3 {
        font-size: 1.25rem;
        margin-bottom: 2.875rem;
      }

      ${Number} {
        font-size: 8.75rem;
      }

      ${ImageBag} {
        width: 7.5rem;
      }
    }
  `}
`

export const ShapeRight = styled.section<ExpiredType & ShapeImageUrlType>`
  ${({ theme, expired = false, imageUrl }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: url(${imageUrl}) right center no-repeat;
    background-size: contain;
    width: 100%;
    min-width: 22.625rem;
    height: 100%;

    h2 {
      font-family: ${theme.font.quicksand};
      font-weight: 400;
      color: ${theme.colors.gray};
      font-size: 2.125rem;
      text-align: center;
    }

    h3 {
      font-family: ${theme.font.nunito};
      font-weight: 900;
      color: ${theme.colors.gray};
      font-size: 0.625rem;
    }

    background-size: 32.5rem;

    ${Number} {
      font-size: 5rem;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      background-size: 63.75rem;

      ${Number} {
        font-size: ${expired ? '7.5rem' : '8.75rem'};
      }

      h2 {
        font-size: ${expired ? '2.75rem' : '3.375rem'};
      }

      h3 {
        font-size: 1.25rem;
      }
    }
  `}
`

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const ImageBagRight = styled.img`
  width: 15.625rem;

  @media (min-width: 768px) {
    width: 21.875rem;
  }
`

export const ImageSafety = styled.img`
  position: absolute;
  right: 85%;
  top: -20%;
  width: 4.375rem;

  @media (min-width: 768px) {
    width: 5.625rem;
  }
`

export const ImageChat = styled.img`
  position: absolute;
  right: -30px;
  top: -15%;
  width: 3.75rem;

  @media (min-width: 768px) {
    width: 5rem;
  }
`

export const Button = styled.button`
  ${({ theme }) => css`
    width: 154px;
    height: 48px;
    border: none;
    border-radius: 24px;
    background-color: ${theme.colors.sunsetOrange};
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
  `}
`
