import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.bold};
    text-align: left;
    width: 100%;
  `}
`

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.quicksand};
    margin-bottom: ${theme.spacings.small};
    text-align: left;
    width: 100%;
  `}
`

export const ButtonBackToSignIn = styled(Link)`
  ${({ theme }) => css`
    margin-top: 1.5rem;
    color: ${theme.colors.primary};
    font-family: ${theme.font.nunito};
    font-weight: 600;
    transition: all 0.3s ease-in-out 0s;
    line-height: 0.8;
    font-size: 0.875rem;
  `}
`
