import { ReactNode } from 'react'
import { toast, TypeOptions } from 'react-toastify'

import Button from '@/components/Button'

import * as S from './styles'

export type ToastProps = {
  type?: TypeOptions
  title?: string | ReactNode
  message?: string | ReactNode
  hideCloseButton?: boolean
  actionButton?: boolean
  onClickActionButton?: () => void
  actionButtonText?: string

  autoClose?: number | false
  onClose?: () => void
}

const Toast = ({
  type = 'error',
  title,
  message,
  hideCloseButton = false,
  autoClose = 5000,
  actionButton = false,
  actionButtonText = '',
  onClickActionButton,
  onClose = () => console.log('close toast'),
}: ToastProps) => {
  return toast(
    <>
      {!!title && <S.Title>{title}</S.Title>}
      {!!message && <S.Message>{message}</S.Message>}
      {!hideCloseButton && <Button size="small">Okay</Button>}
      {actionButton && (
        <Button size="small" onClick={onClickActionButton}>
          {actionButtonText}
        </Button>
      )}
    </>,
    {
      type,
      autoClose,
      onClose,
      style: {
        pointerEvents: 'auto',
      },
    },
  )
}

Toast.dismiss = toast.dismiss

export default Toast
