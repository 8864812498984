import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import audioBento from '@/assets/audio/bento_notification_2023_v2.mp3'

type AudioContextProps = {
  playing: boolean
  canPlay: boolean
  play: () => void
  playOnce: () => void
  pause: () => void
}

export const AudioContext = createContext<AudioContextProps>(
  {} as AudioContextProps,
)

type AudioProviderProps = {
  children: React.ReactNode
}

export function AudioProvider({ children }: AudioProviderProps) {
  const [playing, setPlaying] = useState(false)
  const [canPlay, setCanPlay] = useState(false)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const play = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.loop = true
      audioRef.current?.play()
      setPlaying(true)
    }
  }, [])

  const playOnce = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.loop = false
      audioRef.current?.play()
    }
  }, [])

  const pause = useCallback(() => {
    if (playing && audioRef.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
      setPlaying(false)
    }
  }, [playing])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onloadeddata = () => {
        setCanPlay(true)
      }
    }
  }, [])

  return (
    <AudioContext.Provider
      value={{
        playing,
        canPlay,
        play,
        playOnce,
        pause,
      }}
    >
      {children}
      <audio src={audioBento} ref={audioRef} />
    </AudioContext.Provider>
  )
}
