import { darken, lighten } from 'polished'
import styled, { css, DefaultTheme } from 'styled-components'

import { AlertProps } from '.'

type WrapperProps = Pick<AlertProps, 'variant'>

const WrapperModifiers = {
  success: (theme: DefaultTheme) => css`
    background-color: ${lighten(0.3, theme.colors.green)};
    color: ${darken(0.1, theme.colors.green)};
  `,
  error: (theme: DefaultTheme) => css`
    background-color: ${lighten(0.3, theme.colors.red)};
    color: ${theme.colors.primary};
  `,
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, variant = 'success' }) => css`
    padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
    border-radius: ${theme.border.radius.xsmall};
    margin-bottom: ${theme.spacings.small};
    display: flex;

    ${WrapperModifiers[variant](theme)}
  `}
`

export const Icon = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacings.xsmall};
  `}
`
