import styled, { css, DefaultTheme } from 'styled-components'

import { TextFieldProps } from '.'

export type IconPositionProps = Pick<TextFieldProps, 'iconPosition' | 'icon'>

export type WrapperProps = Pick<
  TextFieldProps,
  'disabled' | 'variant' | 'iconPosition'
> & {
  error?: boolean
  hasIcon: boolean
}

export const InputWrapper = styled.div`
  display: flex;
  background: #fff;
  border-radius: 13px;
  padding: 0 0.75rem;
  border: 2px solid transparent;
  transition: border 0.3s ease-in-out;
  position: relative;

  &:focus-within {
    border: 2px solid #c5c0c0;
  }
`

export const Input = styled.input<IconPositionProps>`
  ${({ theme, icon, iconPosition }) => css`
    color: ${theme.colors.secondary};
    font-family: 'Quicksand';
    font-size: 0.75rem;
    padding: 0.75rem 0;
    background: transparent;
    border: 0;
    outline: none;
    width: 100%;

    ${icon && css`padding-${iconPosition}: 0.75rem;`}

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1.5rem #fff inset;
      filter: none;
    }

    &::placeholder {
      color: ${theme.colors.gray};
    }
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.secondary};
    cursor: pointer;

    span {
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.medium};
      line-height: 0.063rem;
      display: inline-block;
      top: 0.5rem;
      align-items: center;
      justify-content: center;
      width: 0.875rem;
    }
  `}
`

export const Icon = styled.div<IconPositionProps>`
  ${({ iconPosition }) => css`
    display: flex;
    max-width: 1.375rem;
    max-height: 2.438rem;
    color: #c5c0c0;
    order: ${iconPosition === 'right' ? 1 : 0};

    & > svg,
    & > img {
      width: 100%;
    }
  `}
`

const wrapperModifiers = {
  error: () => css`
    ${Icon},
    ${Label} {
      color: #f34040 !important;
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Label},
    ${Input},
    ${Icon} {
      cursor: not-allowed;
      color: ${theme.colors.gray};

      &::placeholder {
        color: currentColor;
      }
    }
  `,
  material: (
    theme: DefaultTheme,
    hasIcon: boolean,
    iconPosition?: 'left' | 'right',
    disabled?: boolean,
  ) => css`
    ${Label} {
      font-weight: 500;
      font-size: 0.625rem;
      color: ${theme.colors.gray};
    }

    ${Icon} {
      max-width: 1.25rem;
      max-height: 1.25rem;
    }

    ${InputWrapper} {
      border-radius: 0;
      border-bottom: 1px solid #979797;
      padding: 0;
      background-color: transparent;

      ${disabled &&
      css`
        border-bottom: 1px solid #dcdcdc;
      `}

      &:focus-within {
        border: 2px solid transparent;
        border-bottom: 1px solid #c5c0c0;
      }
    }

    ${Input} {
      font-family: 'Quicksand';
      color: ${theme.colors.black};
      font-size: 1rem;
      font-weight: 500;
      padding: 0 0 0.375rem;
      ${hasIcon && iconPosition && css`padding-${iconPosition}: 0.8rem;`}
    }
  `,
}

export const Error = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xsmall};
  `}
`

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error, disabled, iconPosition, hasIcon, variant }) => css`
    width: 100%;

    ${error && wrapperModifiers.error()}
    ${variant === 'material' &&
    wrapperModifiers.material(theme, hasIcon, iconPosition, disabled)}
    ${disabled && wrapperModifiers.disabled(theme)}
  `}
`
