import styled, { css } from 'styled-components'

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.medium};
  `}
`

export const Message = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.medium};
    margin-bottom: 0.5rem;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  `}
`
