import { useEffect, useState } from 'react'

import Button from '@/components/Button'
import useAuth from '@/hooks/useAuth'

import * as S from './styles'

export interface LoadingProps {
  size?: string
}

const Loading = ({ size = '1.25rem' }: LoadingProps) => {
  const [showButton, setShowButton] = useState(false)
  const { Logout } = useAuth()

  function handleSignIn() {
    Logout()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true)
    }, 20000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <S.Wrapper>
      <S.Animation size={size} />

      {showButton && (
        <Button onClick={handleSignIn} minimal>
          Force Sign-in
        </Button>
      )}
    </S.Wrapper>
  )
}

export default Loading
