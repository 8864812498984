import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Error500Props } from '.'

type WrapperProps = Error500Props

export const Wrapper = styled.main<WrapperProps>`
  ${({ theme, variant }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    background-color: ${variant === 2 ? theme.colors.primary : 'inherit'};
  `}
`

type ShapeImageUrlType = {
  imageUrl: string
}

export const Shape = styled.section<ShapeImageUrlType>`
  ${({ theme, imageUrl }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url(${imageUrl}) center center no-repeat;
    background-size: contain;
    width: 100%;
    max-width: 42.5rem;
    min-width: 22.625rem;
    height: 33.063rem;

    h2 {
      font-family: ${theme.font.quicksand};
      font-weight: ${theme.font.medium};
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-family: ${theme.font.quicksand};
      font-weight: ${theme.font.medium};
      color: ${theme.colors.gray};
      font-size: 1.25rem;
      margin-bottom: 2.875rem;
    }

    ${media.lessThan('medium')`
      background-size: 32.5rem;

      h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      h3 {
        font-size: 0.875rem;
        margin-bottom: 2.25rem;
      }
    `}
  `}
`

export const ShapeRight = styled.section<ShapeImageUrlType>`
  ${({ theme, imageUrl }) => css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: url(${imageUrl}) right center no-repeat;
    background-size: contain;
    width: 100%;
    min-width: 22.625rem;
    height: 100%;

    h2 {
      font-family: ${theme.font.quicksand};
      font-weight: ${theme.font.regular};
      color: ${theme.colors.gray};
      font-size: 3.375rem;
    }

    h3 {
      font-family: ${theme.font.nunito};
      font-weight: ${theme.font.extraBold};
      color: ${theme.colors.gray};
      font-size: 1.25rem;
    }

    ${Number} {
      font-size: 9.375rem;
      line-height: 0.85;
      margin-bottom: 2rem;
    }

    ${media.lessThan('medium')`
      flex-direction: column;
      justify-content: center;
      background-size: cover;
      background-position: -40% center;

      ${Text} {
        order: 1;
        margin-top: 2rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      h3 {
        font-size: 0.875rem;
      }

      ${Number} {
        font-size: 6.25rem;
      }
    `}
  `}
`

export const Number = styled.h1`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.extraBold};
    color: ${theme.colors.primary};
    font-size: 8.75rem;
    line-height: 1;

    ${media.lessThan('medium')`
      font-size: 5rem;
    `}
  `}
`

export const ImageBag = styled.img`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.xsmall};
    width: 7.5rem;

    ${media.lessThan('medium')`
      margin: 0 ${theme.spacings.xxsmall};
      width: 4.375rem;
    `}
  `}
`

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const ImageBagRight = styled.img`
  width: 21.875rem;

  ${media.lessThan('medium')`
    width: 15.625rem;
  `}
`

export const ImageSafety = styled.img`
  position: absolute;
  right: 85%;
  top: -20%;
  width: 5.625rem;

  ${media.lessThan('medium')`
    width: 4.375rem;
  `}
`

export const ImageChat = styled.img`
  position: absolute;
  right: -30px;
  top: -15%;
  width: 5rem;

  ${media.lessThan('medium')`
      width: 3.75rem;
  `}
`
