import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import authService from '@/api/auth.service'
import Alert from '@/components/Alert'
import Button from '@/components/Button'
import TextField from '@/components/TextField'

import * as S from './styles'

const FormForgotPassword = () => {
  const [searchParams] = useSearchParams()
  const queryEmail = z.string().parse(searchParams.get('email') ?? '')
  const [email, setEmail] = useState(queryEmail as string)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const { mutateAsync: forgotPassword } = useMutation({
    mutationFn: authService.forgotPassword,
  })

  const handleForgotPassword = async (event: React.FormEvent) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      await forgotPassword(email)
      setSuccess(
        `If an account matches ${email}, you should receive an email with instructions on how to reset your password shortly.`,
      )
    } catch (e) {
      setError('Error when reset password, please try again later')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <S.Wrapper onSubmit={handleForgotPassword}>
      {!!error && <Alert variant="error">{error}</Alert>}

      {!!success && <Alert>{success}</Alert>}

      <S.Title>Forgot your password?</S.Title>
      <S.Description>
        Please enter your email address to request a password reset.
      </S.Description>

      <TextField
        type="email"
        name="email"
        label="Email Address"
        labelFor="email"
        value={email}
        onChange={(event) => setEmail(event.currentTarget.value)}
      />

      <Button type="submit" loading={isLoading} disabled={isLoading} fullwidth>
        Request password reset
      </Button>

      <S.ButtonBackToSignIn to="/sign-in">Back to Sign in</S.ButtonBackToSignIn>
    </S.Wrapper>
  )
}

export default FormForgotPassword
