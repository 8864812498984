export const OrderStatusEnum = {
  CREATED: 'created',
  IN_PROGRESS: 'in_progress',
  READY: 'ready',
  DELIVERED: 'delivered',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  PICKED: 'picked',
  IN_TRANSIT: 'in_transit',
  COMPLETED: 'completed',
}
