import { ErrorOutline as ErrorOutlineIcon } from '@styled-icons/material/ErrorOutline'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@styled-icons/material-outlined/CheckCircleOutline'
import { CSSProperties, ReactNode } from 'react'

import * as S from './styles'

export type AlertProps = {
  children: ReactNode
  variant?: 'success' | 'error'
  icon?: JSX.Element
  style?: CSSProperties
}

const Alert = ({ icon, variant, children, style }: AlertProps) => (
  <S.Wrapper variant={variant} style={style}>
    <S.Icon>
      {!!icon && icon}
      {!icon && variant === 'success' ? (
        <CheckCircleOutlineIcon size={24} />
      ) : (
        <ErrorOutlineIcon size={24} />
      )}
    </S.Icon>
    {children}
  </S.Wrapper>
)

export default Alert
