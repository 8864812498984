import { darken, lighten } from 'polished'
import styled, { css, DefaultTheme } from 'styled-components'

import { Animation as LoadingAnimation } from '@/components/Loading/styles'

import { ButtonProps } from '.'

export type WrapperProps = {
  hasicon: number
  isloading: number
  hasshadow: number
  fullwidth?: number
  minimal?: number
} & Pick<ButtonProps, 'color' | 'size' | 'iconside'>

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    height: 1.875rem;
    line-height: 0.5;
    font-size: ${theme.font.sizes.xsmall};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xxsmall};
  `,
  medium: (theme: DefaultTheme) => css`
    height: 2.25rem;
    line-height: 0.8;
    font-size: ${theme.font.sizes.small};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
  `,
  large: (theme: DefaultTheme) => css`
    height: 3.125rem;
    font-size: ${theme.font.sizes.medium};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xlarge};
  `,
  fullwidth: () => css`
    width: 100%;
  `,
  withIcon: (iconside?: 'left' | 'right') => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${iconside === 'left' ? 'row' : 'row-reverse'};

    svg {
      width: 0.938rem;
      max-height: 0.938rem;

      & + span {
        ${iconside === 'left' &&
        css`
          margin-left: 0.313rem;
        `}
        ${iconside === 'right' &&
        css`
          margin-right: 0.313rem;
        `}
      }
    }
  `,
  minimal: (theme: DefaultTheme, loading?: boolean) => css`
    background: none;
    color: ${theme.colors.primary};

    &:hover {
      color: ${darken(0.2, theme.colors.primary)};
    }

    ${loading &&
    css`
      ${LoadingAnimation} {
        border-color: rgba(255, 76, 76, 0.2);
        border-left-color: ${theme.colors.primary};
      }
    `}
  `,
  loading: (theme: DefaultTheme, iconside?: 'left' | 'right') => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${iconside === 'left' ? 'row' : 'row-reverse'};

    ${LoadingAnimation} {
      width: 0.938rem;
      height: 0.938rem;
      border-color: ${theme.colors.white};
      border-left-color: ${lighten(0.1, theme.colors.primary)};

      & + span {
        ${iconside === 'left' &&
        css`
          margin-left: 0.313rem;
        `}
        ${iconside === 'right' &&
        css`
          margin-right: 0.313rem;
        `}
      }
    }
  `,
  disabled: (theme: DefaultTheme, minimal?: boolean) => css`
    background: ${lighten(0.1, theme.colors.primary)};
    cursor: not-allowed;

    &:hover {
      background: ${lighten(0.1, theme.colors.primary)};
    }

    ${minimal &&
    css`
      background: none;
      color: ${lighten(0.1, theme.colors.primary)};

      &:hover {
        background: none;
        color: ${lighten(0.1, theme.colors.primary)};
      }
    `}
  `,
  primary: (theme: DefaultTheme, minimal?: boolean, disabled?: boolean) => css`
    background: ${!minimal ? theme.colors.primary : 'none'};
    color: ${!minimal ? theme.colors.primaryText : theme.colors.primary};

    &:hover {
      background: ${minimal ? 'none' : darken(0.2, theme.colors.primary)};
    }

    ${disabled &&
    css`
      color: ${lighten(
        0.1,
        !minimal ? theme.colors.white : theme.colors.primary,
      )};
      background: ${!minimal ? lighten(0.1, theme.colors.primary) : 'none'};

      &:hover {
        color: ${lighten(
          0.1,
          !minimal ? theme.colors.white : theme.colors.primary,
        )};
        background: ${!minimal ? lighten(0.1, theme.colors.primary) : 'none'};
      }
    `}
  `,
  white: (theme: DefaultTheme, minimal?: boolean, disabled?: boolean) => css`
    background: ${!minimal ? theme.colors.secondaryButtonBg : 'none'};
    color: ${theme.colors.primary};

    &:hover {
      background: ${minimal ? 'none' : darken(0.02, theme.colors.white)};
    }

    ${disabled &&
    css`
      color: ${lighten(0.1, theme.colors.primary)};

      &:hover {
        color: ${lighten(0.1, theme.colors.primary)};
      }
    `}
  `,
  shadow: (theme: DefaultTheme) => css`
    box-shadow: 0 0.188rem 0.625rem 0 ${theme.colors.buttonShadowColor};
  `,
}

export const Wrapper = styled.button<WrapperProps>`
  ${({
    theme,
    size,
    color,
    fullwidth,
    hasicon,
    iconside,
    minimal,
    isloading,
    hasshadow,
    disabled,
  }) => css`
    border: 0;
    border-radius: ${theme.border.radius.large};
    cursor: pointer;
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.semiBold};
    transition: all ${theme.transition.default};

    ${!!size && wrapperModifiers[size](theme)};
    ${fullwidth === 1 && wrapperModifiers.fullwidth()};
    ${hasicon === 1 && wrapperModifiers.withIcon(iconside)};
    ${isloading === 1 && wrapperModifiers.loading(theme, iconside)};
    ${minimal === 1 && wrapperModifiers.minimal(theme, isloading === 1)};
    ${disabled && wrapperModifiers.disabled(theme, minimal === 1)};
    ${!!color && wrapperModifiers[color](theme, minimal === 1, disabled)};
    ${hasshadow === 1 && wrapperModifiers.shadow(theme)};
  `}
`
