export const formatCurrencyWithSymbol = (
  value: number,
  locale = 'en-KY',
  currency = 'KYD',
) => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export const formatCurrencyWithCode = (
  value: number,
  locale = 'en-KY',
  currency = 'KYD',
) => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export const formatCurrency = (
  value: number,
  locale = 'en-KY',
  currency = 'KYD',
) => {
  return Intl.NumberFormat(locale, {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export const formatNumber = (value: number, locale = 'en-KY') => {
  return Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
  }).format(value)
}

export const formatPercentage = (value: number, locale = 'en-KY') => {
  return Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export const formatValueNumber = (value: string) => {
  return Number(
    value.replace(/\D/g, '').replaceAll(',', '').split('.').join(''),
  )
}

export const formatExtNumber = (value: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return value >= item.value
    })
  return item
    ? (value / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}
