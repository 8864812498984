import { Helmet } from 'react-helmet-async'

import FormForgotPassword from './components/FormForgotPassword'

export function ForgotPassword() {
  return (
    <>
      <Helmet title="Forgot Password" />
      <FormForgotPassword />
    </>
  )
}
