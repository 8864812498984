export default {
  name: 'Dark',
  light: false,
  dark: true,
  grid: {
    container: '100%',
    gutter: '2rem', // 32px
  },
  border: {
    radius: {
      xsmall: '13px',
      small: '18px',
      medium: '24px',
      large: '30px',
      xlarge: '32px',
    },
  },
  font: {
    quicksand:
      "Quicksand, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    nunito:
      "Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    sizes: {
      xxsmall: '0.625rem', // 10px
      xsmall: '0.75rem', // 12px
      small: '0.875rem', // 14px
      medium: '1rem', // 16px
      large: '1.25rem', // 20px
      xlarge: '1.75rem', // 28px
      xxlarge: '2rem', // 32px
    },
  },
  colors: {
    primary: '#ff4c4c',
    primaryText: '#FFFFFF',
    secondary: '#433d3d',
    secondaryBg: '#2E2A2A',
    body: '#FFFFFF',
    mainBg: '#433D40',
    borderColor: '#433D40',
    superLight: '#D9D9D9',
    secondaryButtonBg: '#FFFFFF',
    buttonShadowColor: 'none',

    white: '#2E2A2A',
    black: '#FFFFFF',
    lightGray: '#c5c0c0',
    gray: '#D9D9D9',
    supportSuperLight: '#D9D9D9',
    supportDarker: '#eae2e2',
    supportGray: '#c7bbc1',
    red: '#f34040',
    green: '#43cb8f',
    loadingGradient: 'rgba(55, 50, 53, 1)',
    secondaryYellow: '#FBA240',
  },
  spacings: {
    xxsmall: '0.75rem', // 12px
    xsmall: '1rem', // 16px
    small: '1.5rem', // 24px
    medium: '1.75rem', // 28px
    large: '2rem', // 32px
    xlarge: '2.125rem', // 34px
    xxlarge: '2.375rem', // 38px
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },
} as const
