import { lazy, Suspense } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import Loading from './components/Loading'
import { Providers } from './contexts/Providers'
import { Permission, PermissionToPath } from './enums/permission.enum'
import useAuth from './hooks/useAuth'
import { NotFound } from './pages/404'
import { Error500Page } from './pages/500'
import { PageLoading } from './pages/app/loading'
import { ForgotPassword } from './pages/auth/forgot-password'
import { SignIn } from './pages/auth/sign-in'
import { Error } from './pages/error'
const AppLayout = lazy(() =>
  import('./pages/_layouts/app').then((module) => ({
    default: module.AppLayout,
  })),
)
const AuthLayout = lazy(() =>
  import('./pages/_layouts/auth').then((module) => ({
    default: module.AuthLayout,
  })),
)
const LiveOrders = lazy(() =>
  import('./pages/app/live-orders').then((module) => ({
    default: module.LiveOrders,
  })),
)
const Dashboard = lazy(() =>
  import('./pages/app/dashboard').then((module) => ({
    default: module.Dashboard,
  })),
)
const History = lazy(() =>
  import('./pages/app/orders').then((module) => ({
    default: module.History,
  })),
)
const MenuMaker = lazy(() =>
  import('./pages/app/menu-maker').then((module) => ({
    default: module.MenuMaker,
  })),
)
const SuperPartner = lazy(() =>
  import('./pages/app/super-partner').then((module) => ({
    default: module.SuperPartner,
  })),
)
const Settings = lazy(() =>
  import('./pages/app/settings').then((module) => ({
    default: module.Settings,
  })),
)

interface ProtectedRouteProps {
  permission: string
  children: React.ReactNode
}

const ProtectedRoute = ({ permission, children }: ProtectedRouteProps) => {
  const { user, isLoading } = useAuth()
  const isAllowed = user && !isLoading && user.permissions.includes(permission)

  if (!user && !isLoading) {
    return <Navigate to="/sign-in" replace />
  }

  if (!isAllowed) {
    return (
      <Navigate
        to={PermissionToPath[user?.permissionsOrdered[0] || '']}
        replace
      />
    )
  }

  return children
}

const RedirectSignIn = ({ children }: { children: React.ReactNode }) => {
  const { user, isLoading } = useAuth()

  if (user && !isLoading) {
    return <Navigate to={PermissionToPath[user?.permissionsOrdered[0] || '']} />
  }

  return children
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Providers>
        <Suspense fallback={<Loading size="2.5rem" />}>
          <AppLayout />
        </Suspense>
      </Providers>
    ),
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute permission={Permission.LIVE_ORDERS}>
            <Suspense fallback={<PageLoading />}>
              <LiveOrders />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: '/dashboard',
        element: (
          <ProtectedRoute permission={Permission.DASHBOARD}>
            <Suspense fallback={<PageLoading />}>
              <Dashboard />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: '/orders',
        element: (
          <ProtectedRoute permission={Permission.ORDERS}>
            <Suspense fallback={<PageLoading />}>
              <History />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: '/',
        children: [
          {
            path: '/menu-maker',
            element: <Navigate to="/menu-maker/overview" replace />,
          },
          {
            path: '/menu-maker/overview',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <Suspense fallback={<PageLoading />}>
                  <MenuMaker activeTab={0} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: '/menu-maker/menus',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <Suspense fallback={<PageLoading />}>
                  <MenuMaker activeTab={1} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: '/menu-maker/categories',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <Suspense fallback={<PageLoading />}>
                  <MenuMaker activeTab={2} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: '/menu-maker/items',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <Suspense fallback={<PageLoading />}>
                  <MenuMaker activeTab={3} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: '/menu-maker/modifiers',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <Suspense fallback={<PageLoading />}>
                  <MenuMaker activeTab={4} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: '/tutorials/:videoSlug',
        element: (
          <ProtectedRoute
            permission={Permission.LIVE_ORDERS || Permission.MENU_MAKER}
          >
            <MenuMaker activeTab={0} />
          </ProtectedRoute>
        ),
      },
      {
        path: '/',
        children: [
          {
            path: '/super-partner',
            element: <Navigate to="/super-partner/new-campaign" replace />,
          },
          {
            path: '/super-partner/new-campaign',
            element: (
              <ProtectedRoute permission={Permission.SUPER_PARTNER}>
                <Suspense fallback={<PageLoading />}>
                  <SuperPartner activeTab={0} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: '/super-partner/campaigns',
            element: (
              <ProtectedRoute permission={Permission.SUPER_PARTNER}>
                <Suspense fallback={<PageLoading />}>
                  <SuperPartner activeTab={1} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: '/super-partner/vouchers',
            element: (
              <ProtectedRoute permission={Permission.SUPER_PARTNER}>
                <Suspense fallback={<PageLoading />}>
                  <SuperPartner activeTab={2} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: '/super-partner/payment',
            element: (
              <ProtectedRoute permission={Permission.SUPER_PARTNER}>
                <Suspense fallback={<PageLoading />}>
                  <SuperPartner activeTab={3} />
                </Suspense>
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: '/settings',
        element: (
          <ProtectedRoute permission={Permission.ADMIN}>
            <Suspense fallback={<PageLoading />}>
              <Settings />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin-settings',
        element: (
          <ProtectedRoute permission={Permission.SUPER_ADMIN}>
            <Suspense fallback={<PageLoading />}>
              <Settings admin />
            </Suspense>
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <Providers>
        <RedirectSignIn>
          <Suspense fallback={<Loading size="2.5rem" />}>
            <AuthLayout />
          </Suspense>
        </RedirectSignIn>
      </Providers>
    ),
    errorElement: <Error />,
    children: [
      { path: '/sign-in', element: <SignIn /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
    ],
  },
  {
    path: '/500',
    errorElement: <Error />,
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
])
