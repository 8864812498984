import './global.css'
import './i18n'
import '@iguanads/react/dist/index.css'

import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './app.tsx'
import { env } from './env.ts'

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  tracesSampleRate: 1.0,
  enabled: env.VITE_ENV === 'production',
})

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
