import { sub } from 'date-fns'

export type StorageConfigs = {
  start: string
  end: string
  slideIndex: number
  merchantSelected: string
  merchantsSelected: Array<string>
  theme?: string
  messagingToken?: string
}

const saveDate = (dateRange: [Date, Date]) => {
  const [start, end] = dateRange
  const storage = getConfigs()
  localStorage.setItem(
    'BENTO_CONFIGS',
    JSON.stringify({ ...storage, start, end }),
  )
}

const getDate = (): [Date, Date] => {
  const storage = getConfigs()

  if (storage && storage.start && storage.end) {
    return [new Date(storage.start), new Date(storage.end)]
  }

  return [sub(new Date(), { days: 7 }), new Date()]
}

const saveSlidePosition = (slideIndex: number) => {
  const storage = getConfigs()
  localStorage.setItem(
    'BENTO_CONFIGS',
    JSON.stringify({ ...storage, slideIndex }),
  )
}

const getSlidePosition = (): number => {
  const storage = getConfigs()

  if (storage && storage.slideIndex) {
    return Number(storage.slideIndex)
  }

  return 0
}

const saveMerchantsSelected = (merchantsSelected: string[]) => {
  const storage = getConfigs()
  localStorage.setItem(
    'BENTO_CONFIGS',
    JSON.stringify({
      ...storage,
      merchantsSelected,
    }),
  )
}

const getMerchantsSelected = (): Array<string> => {
  const storage = getConfigs()

  if (storage && storage.merchantsSelected) {
    return storage.merchantsSelected
  }

  return []
}

const saveTheme = (theme: string) => {
  if (typeof window !== 'undefined') {
    const storage = getConfigs()
    if (storage?.theme !== theme) {
      localStorage.setItem(
        'BENTO_CONFIGS',
        JSON.stringify({ ...storage, theme }),
      )
    }
  }
}

const saveMessagingToken = (messagingToken: string) => {
  if (typeof window !== 'undefined') {
    const storage = getConfigs()

    localStorage.setItem(
      'BENTO_CONFIGS',
      JSON.stringify({ ...storage, messagingToken }),
    )
  }
}

const getMessagingToken = (): string => {
  const storage = getConfigs()

  if (storage && storage.messagingToken) {
    return storage.messagingToken
  }

  return ''
}

const getConfigs = (): StorageConfigs | null => {
  if (typeof window !== 'undefined') {
    const storage = localStorage?.getItem('BENTO_CONFIGS')

    if (storage) {
      return JSON.parse(storage)
    }
  }
  return null
}

export default {
  saveDate,
  getDate,
  saveSlidePosition,
  getSlidePosition,
  saveMerchantsSelected,
  getMerchantsSelected,
  getConfigs,
  saveTheme,
  saveMessagingToken,
  getMessagingToken,
}
