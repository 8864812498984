import { DefaultTheme } from 'styled-components'
import { create } from 'zustand'

import lightTheme from '@/styles/lightTheme'

interface SwitchThemeProps {
  theme: DefaultTheme
  switchTheme: (theme: DefaultTheme) => void
}

const useSwitchTheme = create<SwitchThemeProps>((set) => ({
  theme: lightTheme,
  switchTheme: (theme) => set(() => ({ theme })),
}))

export default useSwitchTheme
