import { AxiosResponse } from 'axios'
import { signInWithEmailAndPassword } from 'firebase/auth'

import { api } from '@/lib/axios'
import { auth } from '@/lib/firebase'
import { AuthUser } from '@/types/auth.types'
import { MerchantType } from '@/types/merchant.types'

export type LoginAuthProps = { email: string; password: string }

const login = ({ email, password }: LoginAuthProps) => {
  return signInWithEmailAndPassword(auth, email, password)
}

const logout = (uuid: string) => {
  return api.post<{ message: string }>(`/users/${uuid}/signOut`)
}

const forgotPassword = (
  email: string,
): Promise<AxiosResponse<{ data: { status: number } }>> => {
  return api.get<{ data: { status: number } }>(`/users/partner/${email}/reset`)
}

const getUser = async (uuid: string) => {
  const { data } = await api.get<AuthUser>(`/users/${uuid}`)
  return data
}

const getMerchant = (id: string): Promise<AxiosResponse<MerchantType>> => {
  return api.get<MerchantType>(`/merchants/${id}`)
}

const getMultipleMerchants = async (ids: Array<string>) => {
  if (ids.length > 0) {
    const { data } = await api.get<MerchantType[]>(
      `/multiple-merchants?merchants=${ids.join(',')}`,
    )
    return data
  }

  return undefined
}

export default {
  login,
  getUser,
  getMerchant,
  getMultipleMerchants,
  logout,
  forgotPassword,
}
