import { Helmet } from 'react-helmet-async'

import ErrorPage from '@/components/ErrorPage'

export function NotFound() {
  return (
    <>
      <Helmet title="404 Page not found" />
      <ErrorPage variant={404} />
    </>
  )
}
