import { Analytics, getAnalytics } from 'firebase/analytics'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Auth, getAuth } from 'firebase/auth'
import { Firestore, getFirestore } from 'firebase/firestore'
import { getMessaging, getToken, Messaging } from 'firebase/messaging'

import configsToStorageService from '@/api/configs-to-storage.service'
import { env } from '@/env'

const firebaseConfig = {
  apiKey: env.VITE_API_KEY,
  authDomain: env.VITE_AUTH_DOMAIN,
  databaseURL: env.VITE_DATABASE_URL,
  projectId: env.VITE_PROJECT_ID,
  storageBucket: env.VITE_STORAGE_BUCKET,
  messagingSenderId: env.VITE_MESSAGING_SENDER_ID,
  appId: env.VITE_APP_ID,
  measurementId: env.VITE_MEASUREMENT_ID,
}

let firebase: FirebaseApp | null = null
let db: Firestore
let auth: Auth
let analytics: Analytics | null = null
let messaging: Messaging | null = null

if (typeof window !== 'undefined') {
  firebase = initializeApp(firebaseConfig)
  db = getFirestore(firebase)
  auth = getAuth(firebase)
  analytics = getAnalytics(firebase)
  messaging = getMessaging(firebase)

  if ('serviceWorker' in navigator) {
    if (messaging) {
      getToken(messaging, {
        vapidKey: env.VITE_VAPID_KEY,
      })
        .then((currentToken) => {
          configsToStorageService.saveMessagingToken(currentToken)
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })
    }
  }
}

export { firebase, auth, db, analytics, messaging }
