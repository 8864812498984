import { Skeleton } from '@iguanads/react'

export function PageLoading() {
  return (
    <div className="flex flex-col p-8">
      <div className="flex w-full items-center justify-between">
        <Skeleton className="h-8 w-[15rem]" />
        <Skeleton className="h-8 w-32" />
      </div>

      <Skeleton className="mt-12 h-8 w-full" />
      <Skeleton className="mt-4 h-8 w-full" />
      <Skeleton className="mt-4 h-8 w-full" />
      <Skeleton className="mt-4 h-8 w-full" />
      <Skeleton className="mt-4 h-8 w-full" />
      <Skeleton className="mt-4 h-8 w-full" />
    </div>
  )
}
