import axios, { AxiosResponse } from 'axios'

import { env } from '@/env'
import { MerchantType } from '@/types/merchant.types'

type MerchantDetailsType = {
  merchants: MerchantType[]
}

const getMerchant = (
  merchantId: string,
): Promise<AxiosResponse<MerchantType>> => {
  return axios.get<MerchantType>(
    `${env.VITE_APP_API_URL}/merchants/${merchantId}`,
  )
}

const getMerchants = (): Promise<AxiosResponse<MerchantDetailsType>> => {
  return axios.get<MerchantDetailsType>(`${env.VITE_APP_API_URL}/merchants`)
}

export default {
  getMerchant,
  getMerchants,
}
