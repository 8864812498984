import { zodResolver } from '@hookform/resolvers/zod'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@styled-icons/material-outlined'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import authService from '@/api/auth.service'
import Alert from '@/components/Alert'
import Button from '@/components/Button'
import TextField from '@/components/TextField'
import { LoginErrorsEnum } from '@/enums/errors.enum'
import { getEnumKeyByEnumValue } from '@/utils/functions/getEnumByKey'

import * as S from './styles'

const authUserCredentialFormSchema = z.object({
  email: z.string().email().min(1, 'Email is required'),
  password: z.string().min(1, 'Password is required'),
})

type AuthUserCredentialsFormData = z.infer<typeof authUserCredentialFormSchema>

const FormSignIn = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const { handleSubmit, watch, control } = useForm<AuthUserCredentialsFormData>(
    {
      resolver: zodResolver(authUserCredentialFormSchema),
      defaultValues: {
        email: '',
        password: '',
      },
    },
  )

  const emailField = watch('email')

  const { mutateAsync: signIn } = useMutation({
    mutationFn: authService.login,
  })

  const handleLogin = async (data: AuthUserCredentialsFormData) => {
    try {
      setIsLoading(true)
      await signIn({ ...data })
    } catch (error) {
      const errorKey = getEnumKeyByEnumValue(LoginErrorsEnum, error as string)
      setError(
        LoginErrorsEnum[errorKey!] || LoginErrorsEnum.INVALID_CREDENTIALS,
      )
      setIsLoading(false)
    }
  }

  return (
    <S.Wrapper onSubmit={handleSubmit(handleLogin)}>
      {!!error && <Alert variant="error">{error}</Alert>}

      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            type="email"
            label="Email"
            labelFor="email"
            id="email"
            error={error?.message}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="Password"
            labelFor="password"
            id="password"
            error={error?.message}
            iconPosition="right"
            icon={
              <S.ButtonShowPassword
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                icon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                minimal
              />
            }
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <Button type="submit" loading={isLoading} disabled={isLoading} fullwidth>
        Sign in
      </Button>

      <S.ButtonForgot
        to={{
          pathname: '/forgot-password',
          search: emailField ? `?email=${emailField}` : '',
        }}
      >
        Forgot password?
      </S.ButtonForgot>
    </S.Wrapper>
  )
}

export default FormSignIn
