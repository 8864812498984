import bentoBagImg from '@/assets/img/bento-bag.svg'
import bentoBagPcImg from '@/assets/img/bento-bag-pc.svg'
import chatImg from '@/assets/img/chat.svg'
import safetyImg from '@/assets/img/safety.svg'
import shapeImg from '@/assets/img/shape-bg.svg'
import shapeRightImg from '@/assets/img/shape-bg-right.svg'
import Button from '@/components/Button'
import { env } from '@/env'
import { getRandomInt } from '@/utils/numbers/random'

import * as S from './styles'

export type Error500Props = {
  variant?: number
  link?: string
  onClick?: () => void
}

type ButtonTryAgainProps = Pick<Error500Props, 'link' | 'onClick'>

const ButtonTryAgain = ({ link, onClick }: ButtonTryAgainProps) =>
  link ? (
    <Button
      as="a"
      href={link}
      onClick={onClick}
      style={{ textDecoration: 'none' }}
    >
      Try again
    </Button>
  ) : (
    <Button onClick={onClick}>Try again</Button>
  )

const Error500 = ({
  variant = getRandomInt(1, 3),
  link,
  onClick,
}: Error500Props) => {
  const url = `https://${
    env.VITE_ENV === 'stage' ? 'stage.' : ''
  }portal.bento.ky`

  return (
    <S.Wrapper variant={variant}>
      {variant !== 3 ? (
        <S.Shape imageUrl={shapeImg}>
          <S.Number>
            5 <S.ImageBag src={bentoBagImg} alt="Bento Bag" /> 0
          </S.Number>

          <h2>Well, this is unexpected…</h2>
          <h3>We’re working to fix the problem</h3>

          <ButtonTryAgain link={link || url} onClick={onClick} />
        </S.Shape>
      ) : (
        <S.ShapeRight imageUrl={shapeRightImg}>
          <S.Text>
            <h3>…OOPS</h3>
            <h2>ERROR</h2>

            <S.Number>500</S.Number>

            <ButtonTryAgain link={link || url} onClick={onClick} />
          </S.Text>

          <S.Images>
            <S.ImageSafety src={safetyImg} alt="Image Safety" />
            <S.ImageBagRight src={bentoBagPcImg} alt="Bento Bag PC" />
            <S.ImageChat src={chatImg} alt="Image Chat" />
          </S.Images>
        </S.ShapeRight>
      )}
    </S.Wrapper>
  )
}

export default Error500
