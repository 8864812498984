import { logEvent, setUserId } from 'firebase/analytics'

import { analytics } from '@/lib/firebase'

export const useAnalytics = () => {
  const defineUserId = (id: string) => {
    setUserId(analytics!, id)
  }

  const logWatchTutorial = (userId: string) => {
    logEvent(analytics!, 'watch_tutorial', {
      userId,
    })
  }

  return {
    logWatchTutorial,
    defineUserId,
  }
}
