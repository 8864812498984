/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@tanstack/react-query'
import { getIdToken, onAuthStateChanged, User } from 'firebase/auth'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import authService from '@/api/auth.service'
import configsToStorageService from '@/api/configs-to-storage.service'
import merchantService from '@/api/merchant.service'
import Loading from '@/components/Loading'
import { LoginErrorsEnum } from '@/enums/errors.enum'
import { Permission, PermissionOrderedList } from '@/enums/permission.enum'
import { useAnalytics } from '@/hooks/useAnalytics'
import { api } from '@/lib/axios'
import { auth } from '@/lib/firebase'
import { queryClient } from '@/lib/react-query'
import { AuthUser } from '@/types/auth.types'
import { MerchantDetailsType, MerchantType } from '@/types/merchant.types'

type AuthContextProps = {
  user: AuthUser | null
  merchants: MerchantDetailsType[]
  merchantsSelected: MerchantType[]
  merchantSelected: MerchantType | undefined
  isDialogShowed: boolean
  setIsDialogShowed: Dispatch<SetStateAction<boolean>>
  magicVoucherAnimation: boolean
  setMagicVoucherAnimation: Dispatch<SetStateAction<boolean>>
  isOneMerchantSelectedPages: boolean
  isLoading: boolean
  printCharacteristic: any | null
  timezone: string
  isSuperAdmin: boolean
  Logout: () => void
  onSetMerchantSelected: (id: string, cbAwait?: () => void) => void
  onSetMerchantsSelected: (id: string, isLive?: boolean) => void
  onPrintCharacteristic: (characteristic: any) => void
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
)

type AuthProviderProps = {
  children: React.ReactNode
}

function clearIndexedDbs() {
  window.indexedDB.databases().then((dbs) => {
    dbs.forEach((db) => {
      if (db.name) {
        window.indexedDB.deleteDatabase(db.name)
      }
    })
  })
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [userUid, setUserUid] = useState<string | null>(null)
  const [user, setUser] = useState<AuthUser | null>(null)
  const [merchants, setMerchants] = useState<MerchantDetailsType[]>([])
  const [merchantsSelected, setMerchantsSelected] = useState<MerchantType[]>([])
  const [timezone, setTimezone] = useState<string>('America/Cayman')
  const [isLoading, setIsLoading] = useState(true)
  const [isDialogShowed, setIsDialogShowed] = useState(false)
  const [printCharacteristic, setPrintCharacteristic] = useState<any>(null)
  const [magicVoucherAnimation, setMagicVoucherAnimation] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { defineUserId } = useAnalytics()

  const merchantSelected = useMemo(() => {
    return (
      (merchantsSelected &&
        merchantsSelected[merchantsSelected.length - 1 || 0]) ||
      undefined
    )
  }, [merchantsSelected])

  const isOneMerchantSelectedPages =
    pathname.includes('menu-maker') ||
    pathname.includes('settings') ||
    pathname.includes('super-partner')

  const isSuperAdmin =
    user?.permissions.includes(Permission.SUPER_ADMIN) || false

  const { data: userLogged } = useQuery({
    enabled: !!userUid,
    queryKey: ['user'],
    queryFn: () => authService.getUser(userUid!),
  })

  const getUserId = async (user: User | null) => {
    if (!user) {
      setIsLoading(false)
      return
    }

    const token = await user.getIdToken()
    api.defaults.headers.Authorization = `Bearer ${token}`

    setUserUid(user.uid)
    defineUserId(user.uid)
  }

  const { mutateAsync: signOut } = useMutation({
    mutationFn: authService.logout,
    onSuccess: async () => {
      clearLogout()
    },
    onError: async () => {
      clearLogout()
    },
  })

  const clearLogout = useCallback(async () => {
    setIsLoading(true)
    setUserUid(null)
    setMerchantsSelected([])
    setMerchants([])
    clearIndexedDbs()
    api.defaults.headers.Authorization = ''
    queryClient.invalidateQueries({ queryKey: ['user'] })
    if ('onLine' in navigator && navigator.onLine) {
      navigate('/sign-in', { replace: true })
    }
  }, [navigate])

  const onSetMerchantSelected = useCallback(
    async (id: string, cbAwait?: () => void) => {
      const { data: merchant } = await merchantService.getMerchant(id)
      configsToStorageService.saveMerchantsSelected([merchant.id])
      setMerchantsSelected([merchant])
      cbAwait && cbAwait()
    },
    [],
  )

  const onSetMerchantsSelected = useCallback(
    async (id: string, isLive = false) => {
      const newSelected = [...merchantsSelected]
      const isAlreadySelected = newSelected.some((m) => m.id === id)

      if (isAlreadySelected && newSelected.length === 1) return
      if (!isAlreadySelected && newSelected.length === 10) return

      const { data: merchant } = await merchantService.getMerchant(id)

      if (isLive) {
        const selectedIndex = newSelected.findIndex((m) => m.id === id)
        newSelected[selectedIndex] = merchant
        setMerchantsSelected(newSelected)
        return
      }

      if (isAlreadySelected) {
        const selected = newSelected.filter((m) => m.id !== id)
        configsToStorageService.saveMerchantsSelected(selected.map((m) => m.id))
        setMerchantsSelected(selected)
      } else {
        const selected = [...newSelected, merchant]
        configsToStorageService.saveMerchantsSelected(selected.map((m) => m.id))
        setMerchantsSelected(selected)
      }
    },
    [merchantsSelected],
  )

  useEffect(() => {
    setTimezone(merchantsSelected[0]?.timeZone)
  }, [merchantsSelected])

  const onPrintCharacteristic = useCallback(async (characteristic: any) => {
    setPrintCharacteristic(characteristic)
  }, [])

  const Logout = useCallback(async () => {
    await signOut(userUid!)
  }, [signOut, userUid])

  const getUser = useCallback(async () => {
    if (!userLogged) {
      await Logout()
    }

    if (!userLogged?.permissions) {
      await Logout()
      return Promise.reject(LoginErrorsEnum.PERMISSION)
    }

    const permissions = userLogged.permissions.filter((item) => {
      const itemKey = item.replace(/[-\s]/g, '_').toUpperCase()
      if (itemKey in Permission) {
        return item
      }
      return false
    })

    const permissionsOrdered = PermissionOrderedList.filter((item) => {
      return permissions.includes(item)
    })

    setUser({ ...userLogged, permissions, permissionsOrdered })
    try {
      if (!merchants.length) {
        if (merchantsSelected && !merchantsSelected.length) {
          const merchantsSelectedStorage =
            configsToStorageService.getMerchantsSelected()
          const merchantsSelectedOrFirst =
            merchantsSelectedStorage.length > 0
              ? merchantsSelectedStorage
              : [userLogged.merchantDetails[0].id]
          const merchantsMultiple = await authService.getMultipleMerchants(
            merchantsSelectedOrFirst,
          )
          if (merchantsMultiple) {
            setMerchantsSelected(merchantsMultiple)
          }
        }
        setMerchants(
          userLogged.merchantDetails.map((m) => ({ ...m, selected: false })),
        )
      }
    } catch (error) {
      await Logout()
    } finally {
      setIsLoading(false)
    }
  }, [Logout, merchants.length, merchantsSelected, userLogged])

  //  force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(
      async () => {
        if (auth.currentUser) {
          getIdToken(auth.currentUser, true).then((token) => {
            api.defaults.headers.Authorization = `Bearer ${token}`
          })
        }
      },
      10 * 60 * 1000,
    )
    return () => clearInterval(handle)
  }, [])

  useEffect(() => {
    if (user) return
    onAuthStateChanged(auth, getUserId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (merchantsSelected && merchantsSelected.length) {
      setMerchants((old) => {
        return old.map((m) => ({
          ...m,
          selected: !!merchantsSelected.find((mer) => mer.id === m.id),
        }))
      })
    }
  }, [merchantsSelected])

  useEffect(() => {
    if (userLogged) {
      getUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogged])

  useEffect(() => {
    if (isOneMerchantSelectedPages && merchantsSelected.length > 1) {
      setMerchantsSelected((old) => [old[old.length - 1 || 0]])
    }
  }, [isOneMerchantSelectedPages, merchantsSelected])

  return (
    <AuthContext.Provider
      value={{
        user,
        merchants,
        merchantSelected,
        merchantsSelected,
        isDialogShowed,
        setIsDialogShowed,
        magicVoucherAnimation,
        setMagicVoucherAnimation,
        isOneMerchantSelectedPages,
        isLoading,
        printCharacteristic,
        timezone,
        isSuperAdmin,
        Logout,
        onSetMerchantSelected,
        onSetMerchantsSelected,
        onPrintCharacteristic,
      }}
    >
      {isLoading ? <Loading size="2.5rem" /> : children}
    </AuthContext.Provider>
  )
}
