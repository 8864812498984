import { forwardRef, InputHTMLAttributes } from 'react'

import {
  creditCardAlterMask,
  creditCardMask,
  dateMonthYearMask,
  moneyMask,
  numberCleanMask,
  numberMask,
  phoneMask,
  phoneMaskFormatted,
} from '@/utils/formats/masks'

import * as S from './styles'

const masks = {
  money: (value: string) => moneyMask(value),
  creditCard: (value: string) => creditCardMask(value),
  dateMonthYear: (value: string) => dateMonthYearMask(value),
  creditCardAlter: (value: string) => creditCardAlterMask(value),
  number: (value: string, min?: string | number, max?: string | number) => {
    if (Number(min) && Number(value) < Number(min)) {
      value = String(min)
    }
    if (Number(max) && Number(value) > Number(max)) {
      value = String(max)
    }
    return numberMask(String(value))
  },
  numberClean: (value: string) => String(numberCleanMask(value)),
  phone: (value: string) => phoneMask(value),
  phoneFormatted: (value: string) => phoneMaskFormatted(value),
}

export type TextFieldProps = {
  label?: string
  labelFor?: string
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  error?: string
  variant?: 'default' | 'material'
  mask?:
    | 'money'
    | 'number'
    | 'creditCard'
    | 'creditCardAlter'
    | 'dateMonthYear'
    | 'numberClean'
    | 'phone'
    | 'phoneFormatted'
  isRequired?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const TextField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    icon,
    iconPosition = 'left',
    label,
    labelFor = '',
    value = '',
    error,
    disabled = false,
    variant = 'default',
    mask,
    max,
    min,
    isRequired,
    onChange,
    ...props
  },
  ref,
) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    const newValue = mask ? masks[mask](value, min, max) : value
    !!onChange &&
      onChange({ ...e, currentTarget: { ...e.currentTarget, value: newValue } })
  }

  return (
    <S.Wrapper
      disabled={disabled}
      error={!!error}
      variant={variant}
      iconPosition={iconPosition}
      hasIcon={!!icon}
    >
      {!!label && (
        <S.Label htmlFor={labelFor}>
          {label} {!!isRequired && <span>*</span>}
        </S.Label>
      )}
      <S.InputWrapper>
        {!!icon && <S.Icon iconPosition={iconPosition}>{icon}</S.Icon>}
        <S.Input
          type="text"
          onChange={handleOnChange}
          value={mask ? masks[mask](value as string, min, max) : value}
          iconPosition={iconPosition}
          disabled={disabled}
          aria-invalid={error ? 'true' : 'false'}
          ref={ref}
          {...props}
        />
      </S.InputWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}

export default forwardRef(TextField)
