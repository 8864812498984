export const Permission = {
  DASHBOARD: 'dashboard',
  SENTRY: 'sentry',
  LIVE_ORDERS: 'live-orders',
  ORDERS: 'orders',
  MENU_MAKER: 'menu-maker',
  SUPER_PARTNER: 'super-partner',
  ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin',
  MASTER: 'master',
}

export const PermissionToPath: { [key: string]: string } = {
  [Permission.LIVE_ORDERS]: '/',
  [Permission.SENTRY]: '/sentry',
  [Permission.DASHBOARD]: '/dashboard',
  [Permission.ORDERS]: '/orders',
  [Permission.MENU_MAKER]: '/menu-maker',
  [Permission.SUPER_PARTNER]: '/super-partner',
  [Permission.ADMIN]: '/settings',
  [Permission.SUPER_ADMIN]: '/admin-settings',
  [Permission.MASTER]: '/master',
}

export const PathToPermission: { [key: string]: string } = {
  '/': Permission.LIVE_ORDERS,
  '/sentry': Permission.SENTRY,
  '/dashboard': Permission.DASHBOARD,
  '/orders': Permission.ORDERS,
  '/menu-maker': Permission.MENU_MAKER,
  '/super-partner': Permission.SUPER_PARTNER,
  '/settings': Permission.ADMIN,
  '/admin-settings': Permission.SUPER_ADMIN,
  '/master': Permission.MASTER,
}

export const PermissionOrderedList = [
  Permission.LIVE_ORDERS,
  Permission.DASHBOARD,
  Permission.ORDERS,
  Permission.MENU_MAKER,
  Permission.SUPER_PARTNER,
  Permission.ADMIN,
  Permission.SUPER_ADMIN,
  Permission.SENTRY,
  Permission.MASTER,
]
