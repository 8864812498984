import { Helmet } from 'react-helmet-async'

import FormSignIn from './components/FormSignIn'

export function SignIn() {
  return (
    <>
      <Helmet title="Sign In" />
      <FormSignIn />
    </>
  )
}
