import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enJSON from '@/locale/en.json'

import { env } from './env'

i18n.use(initReactI18next).init({
  debug: env.VITE_ENV === 'stage',
  lng: 'en',
  resources: {
    en: { ...enJSON },
  },
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
