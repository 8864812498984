import styled, { css, keyframes } from 'styled-components'

import { LoadingProps } from '.'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 95vh;

  button {
    position: absolute;
    bottom: 1.875rem;
  }
`

const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Animation = styled.div<LoadingProps>`
  ${({ size = '1.25rem' }) => css`
    border-radius: 50%;
    width: ${size};
    height: ${size};
    position: relative;
    border-top: 3px solid rgba(255, 76, 76, 0.2);
    border-right: 3px solid rgba(255, 76, 76, 0.2);
    border-bottom: 3px solid rgba(255, 76, 76, 0.2);
    border-left: 3px solid #ff4c4c;
    transform: translateZ(0);
    animation: ${load8} 1.1s infinite linear;

    &:after {
      border-radius: 50%;
      width: ${size};
      height: ${size};
    }
  `}
`
