import { Helmet } from 'react-helmet-async'

import Error500 from '@/components/Error500'

export function Error500Page() {
  return (
    <>
      <Helmet title="500" />
      <Error500 />
    </>
  )
}
